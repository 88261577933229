.journey-slider {
    position: absolute;
    top: 0;
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 5vw;
    overflow-x: hidden;
    overflow-y: hidden;
    /* padding-right: 5vw; */
}

/* CARD */
.journey-card {
    height: 80vh;
    width: 25vw;
    background-size: cover !important;
    background-position: center center !important;
    border-radius: 20px;
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    height: 80vh;
    width: 25vw;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    border-radius: 20px;
}

.card-content {
    position: absolute;
    bottom: 20px;
    color: white;
    font-family: "Milliard";
    font-size: 1.25rem;
    padding: 0 5%;
}

@media screen and (max-width: 900px) {
    .journey-slider {
        position: static;
        overflow: hidden;
    }
    .journey-card {
        width: 80vw;
        height: 60vh;
    }
    .overlay {
        width: 80vw;
        height: 60vh;
    }
}
