.blog-card {
    /* height: 60vh; */
    position: relative;
    width: 360px;
    /* border: 1px solid red; */
    /* flex-grow: 0; */
    flex-shrink: 0;
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

.blog-card-img {
    height: 40vh;
    width: 100%;
    object-fit: cover;
}

.blog-card-arrow {
    position: absolute;
    height: 30px;
    width: 30px;
    display: grid;
    place-items: center;
    top: calc(40vh - 10px);
    right: 10px;
    transform: translateY(-100%);
    border-radius: 50%;
    border: 1px solid white;
    background-color: rgba(217, 217, 217, 0.19);
    transition: 0.3s;
}

.blog-card:hover .blog-card-arrow {
    background-color: #2eaf7d;
    transform: translateY(-100%) rotate(-45deg);
}

.blog-card-content {
    padding: 25px 10px;
}

.blog-card-head {
    font-family: "Milliard";
    font-weight: bolder;
    font-size: 1rem;
    color: black;
}

.blog-card-para {
    font-family: "Milliard";
    color: #303030;
}

@media screen and (max-width: 900px) {
    .blog-card {
        width: 100%;
        margin-left: 0 !important;
    }
    .blog-card-img {
        height: 30vh;
    }
    .blog-card-content {
        padding: 5px 10px 20px 10px;
    }
    .blog-card-head {
        font-size: 1rem;
    }
    .blog-card-para {
        display: none;
    }
    .blog-card-arrow {
        top: calc(30vh - 10px);
    }
}
