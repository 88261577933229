.journey {
    height: 185vh;
    padding: 10vh 0 10vh 5vw;
    position: relative;
    /* overflow: hidden; */
}
.journey-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    z-index: 2;
    background-color: white;
    height: 80vh;
    padding-right: 1.5em;
    width: max-content;
}
.journey-content-subtitle {
    color: #858585;
    -webkit-text-stroke-width: 0.5;
    -webkit-text-stroke-color: #858585;
    font-family: "Milliard";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.journey-content-title {
    color: #2eaf7d;
    text-shadow: 0px 4px 4px rgba(1, 51, 19, 0.3);
    font-family: "Florencesans-Black";
    font-size: 6.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
    width: min-content;
}
.journey-slider-container {
    overflow: hidden;
    position: sticky;
    top: 10vh;
    display: flex;
    align-items: center;
    height: 80vh;
}

@media screen and (max-width: 900px) {
    .journey {
        height: 350vh;
    }
    .journey-slider-container {
        flex-direction: column;
        height: 85vh;
        justify-content: space-between;
        top: 5vh;
    }
    .journey-content {
        height: unset;
        background-color: transparent;
        font-size: 1rem;
        width: 95vw;
    }
    .journey-content-title {
        font-size: 3.5rem;
        width: unset;
    }
    .journey-content-subtitle {
        font-size: 1.2rem;
    }
}
