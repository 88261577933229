rect {
    pointer-events: all;
    fill-opacity: 0;
    stroke-opacity: 0;
    z-index: 1;
}

.graph-component {
    background: linear-gradient(
        120.36deg,
        #e7fffb 0%,
        #ffffff 54.27%,
        #ceffd6 107.35%,
        #fcfcfc 115.73%
    );
    padding: 10vh 10vw;
    overflow-x: hidden;
    width: 100vw;
}

#graph-container {
    position: relative;
    transform: translateX(-10vw);
}

.graph-component h1 {
    font-size: 3rem;
    color: #2eaf7d;
    font-family: "Florencesans-Black";
}

.tooltip {
    position: absolute;
    padding: 10px;
    background-color: steelblue;
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    display: none;
    opacity: 0.75;
    pointer-events: none;
}

.graph-user-interaction {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.graph-inputs {
    display: flex;
    gap: 5vw;
}

.graph-input-box {
    display: flex;
    flex-direction: column;
}

.graph-input-box label {
    color: #2eaf7d;
    font-size: 1.2rem;
}

.graph-user-interaction button {
    padding: 10px 20px;
    background-color: #2eaf7d;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
}

#num1,
#num2 {
    padding: 5px 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #e0e5dc;
    background-color: white;
}

.graph-increased {
    margin: 3vh;
    text-align: center;
    font-size: 1.2rem;
}

@media screen and (max-width: 900px) {
    .tooltip {
        display: none !important;
    }
    #graph-container {
        transform: translateX(-5vw);
    }
    .axis-label {
        display: none;
    }
    .graph-component {
        padding: 5vh 5vw;
    }

    .graph-inputs {
        flex-direction: column;
    }

    .graph-user-interaction {
        flex-direction: column;
        align-items: unset;
        gap: 20px;
    }

    .graph-component h1 {
        font-size: 2.5rem;
        font-family: "MilliardBold";
    }
}
