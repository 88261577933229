.career-text-content {
    background: white url("../../Assets/Images/aboutMissionBg.png");
    padding: 15vh 10vw 10vh;
    display: flex;
    flex-direction: column;
    gap: 7vh;
}

.career-text-content div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
    color: #101010;
    font-family: "Milliard";
    font-size: 1.2rem;
}

.career-vision h1 {
    font-family: "Florencesans-Black";
    color: #2eaf7d;
    font-size: 4rem;
}

.career-mission h1 {
    font-family: "Florencesans-Black";
    color: #2eaf7d;
    font-size: 4rem;
}

.career-roles-heading {
    font-family: "MilliardBold";
    text-align: center;
    font-size: 4rem;
    color: #2eaf7d;
    margin: 5vh 0 0;
}

.career-roles {
    display: flex;
    flex-direction: column;
    padding: 5vh 10vw;
}

.position-card {
    width: 80vw;
    border-bottom: 2px solid #2eaf7d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3vh 0;
}

.position-card:hover .position-role {
    color: #2eaf7d;
}

.position-role {
    font-size: 1.5rem;
    color: black;
    transition: 0.3s;
}

.position-location {
    font-size: 1rem;
    color: rgb(0, 0, 0, 0.75);
}

@media screen and (max-width: 900px) {
    .position-role {
        font-size: 1rem;
    }
    .position-location {
        font-size: 0.75rem;
        text-align: right;
    }
}
