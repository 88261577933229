.services-cards-section {
    display: flex;
    flex-direction: column;
    gap: 10vh;
    padding: 10vh 0;
    background: linear-gradient(
        117deg,
        #e7fffe 5.4%,
        #fff 49.4%,
        #dffdfc 96.4%
    );
    overflow: hidden;
}

.services-cards-para {
    color: #2eaf7d;
    font-size: 2.75rem;
    font-family: MilliardBold;
    text-align: center;
    text-transform: uppercase;
}

.services-card-container {
    position: relative;
    display: flex;
    justify-content: flex-start;
}

.services-card-container > div {
    z-index: 2;
    width: 80vw;
    margin: 0 5vw;
}

.cont-opp {
    justify-content: flex-end;
}

.cont-opp > div {
    left: unset;
    right: 5vw;
}

.services-card-num {
    font-family: Florencesans-Black;
    font-size: 6rem;
    color: rgb(1, 77, 74, 0.38);
}

.cont-opp .services-card-num {
    text-align: right;
}

.services-card {
    display: flex;
    align-items: center;
    z-index: 5;
    position: relative;
    background-color: rgba(255, 255, 255, 0.75);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    gap: 3vw;
    padding-right: 5vw;
    height: 40vh;
}

.services-card > img {
    box-shadow: 4px 0 15px rgb(0, 0, 0, 0.25);
    border-radius: 16px;
    height: 100%;
}

.flex-opp {
    flex-direction: row-reverse;
    padding-right: unset;
    padding-left: 5vw;
}

.flex-opp > img {
    box-shadow: -4px 0 15px rgb(0, 0, 0, 0.25);
}

.services-card-content {
    width: 80%;
}

.services-card-head {
    font-family: MilliardBold;
    font-size: 2rem;
    color: #2eaf7d;
    margin-bottom: 15px;
}

.services-card-para {
    font-family: Milliard;
    font-size: 1.1rem;
}

.services-card-container-bg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-40%);
}

.cont-opp > .services-card-container-bg {
    transform: translateY(-40%) rotateZ(180deg);
    left: 0;
    right: unset;
}

@media screen and (max-width: 1300px) {
    .services-card-container > div {
        width: 90vw;
    }

    .services-card {
        height: unset;
    }
    .services-card img {
        /* width: 45vw; */
    }
    .services-card-head {
        font-size: 2.5rem;
    }
    .services-card-para {
        font-size: 1rem;
    }
}

@media screen and (max-width: 900px) {
    .services-card-num {
        display: none;
    }
    .services-card {
        flex-direction: column;
        padding: 0;
        gap: 0;
        height: unset;
        max-height: 50vh;
        transition: max-height 0.2s ease-in-out;
        overflow: hidden;
    }
    .expanded-services-card {
        max-height: 100vh;
    }
    .services-card img {
        width: 100%;
    }
    .services-card-content {
        width: 100%;
        padding: 2vh 5vw;
    }
    .services-card-head {
        font-family: MilliardBold;
        font-size: 1.5rem;
        text-align: center;
    }
    .services-card-para {
        text-align: center;
    }
    .coverBlack {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.6);
        top: 0;
        left: 0;
        z-index: 10;
    }
    .increase-z-index {
        z-index: 50;
    }
}
