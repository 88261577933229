.hero {
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    display: block;
}

#myVideo {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

#hard-light {
    width: 100vw;
    height: 100vh;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(#0b8225, #000);
    mix-blend-mode: hard-light;
}

.hero-main-flex {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    gap: 1.5em;
    /* align-items: center; */
    justify-content: center;
    position: absolute;
    z-index: 5;
    bottom: 2rem;
    left: 5vw;
}
.hero-head {
    color: #2eaf7d;
    font-size: 5rem;
    text-transform: uppercase;
    font-family: "Florencesans-Black";
}
.hero-para {
    color: #c8c8c8;
    /* text-align: center; */
    /* text-shadow: 4px 2px 4px rgba(16, 16, 16, 0.5),
        0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-text-stroke-width: 0.5;
    -webkit-text-stroke-color: #fff; */
    font-family: "Milliard";
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: 80%;
}
@media screen and (max-width: 900px) {
    .hero {
        height: 80vh;
        background-image: url("../../Assets/Images/heroB4.png");
    }
    .hero-head {
        font-size: 4rem;
    }
    .hero-para {
        width: 90%;
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 768px) {
    .hero-para {
        font-size: 1rem;
    }
}
