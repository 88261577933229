.about-hero {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 10vh 5vw;
    height: 100vh;
    gap: 20px;
}
.about-hero-main {
    width: 100%;
    height: 100%;
    flex-basis: 10%;
    border-radius: 8px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.about-hero-main-1 {
    animation: aboutHero 24s ease-in-out 0s infinite;
}

.about-hero-main-2 {
    animation: aboutHero 24s ease-in-out 6s infinite;
}

.about-hero-main-3 {
    animation: aboutHero 24s ease-in-out 12s infinite;
}

.about-hero-main-4 {
    animation: aboutHero 24s ease-in-out 18s infinite;
}

.about-hero-text-1 {
    animation: aboutHeroText 24s ease-in-out 0s infinite;
}

.about-hero-text-2 {
    animation: aboutHeroText 24s ease-in-out 6s infinite;
}

.about-hero-text-3 {
    animation: aboutHeroText 24s ease-in-out 12s infinite;
}

.about-hero-text-4 {
    animation: aboutHeroText 24s ease-in-out 18s infinite;
}

@keyframes aboutHero {
    0% {
        flex-basis: 10%;
    }
    2% {
        flex-basis: 70%;
    }
    23% {
        flex-basis: 70%;
    }
    25% {
        flex-basis: 10%;
    }
    100% {
        flex-basis: 10%;
    }
}

@keyframes aboutHeroText {
    0% {
        opacity: 0;
    }
    2% {
        opacity: 0;
    }
    2.5% {
        opacity: 1;
    }
    22.5% {
        opacity: 1;
    }
    23% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.about-hero-text {
    position: absolute;
    opacity: 0;
    transition: 0s;
    left: 20px;
    bottom: 20px;
    width: 80%;
}
.about-hero-main-title {
    color: var(--white, #fbfcf8);
    font-family: "Florencesans-Black";
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}
.about-hero-main-subtitle {
    color: #fff;
    font-family: "Milliard";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.about-hero-main-subtitle > span {
    color: #fff;
    font-family: "Milliard";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media screen and (max-width: 900px) {
    @keyframes aboutHero {
        0% {
            flex-basis: 0%;
        }
        2% {
            flex-basis: 100%;
        }
        23% {
            flex-basis: 100%;
        }
        25% {
            flex-basis: 0%;
        }
        100% {
            flex-basis: 0%;
        }
    }
    .about-hero {
        gap: 0px;
        padding: 0;
        height: 80vh;
        width: 100vw;
        overflow-x: hidden;
    }
    .about-hero-main {
        flex-basis: 0%;
        border-radius: 0px;
    }
    .about-hero-main-title {
        font-size: 3rem;
    }
    .about-hero-text {
        bottom: 40px;
    }
    .about-hero-main-subtitle {
        font-size: 1.2rem;
    }
    .active-panel {
        flex-basis: 100%;
    }
}
