.mission {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center center;
    gap: 100px;
    padding: 5vh 5vw 15vh 5vw;
    position: relative;
}
.vision {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: center;
    background-size: cover;
    background-position: center center;
    gap: 100px;
    padding: 5vh 5vw 15vh 5vw;
    position: relative;
}

.mission > img,
.vision > img {
    top: 0;
    right: 0;
    position: absolute;
    height: 50%;
    z-index: 2;
    opacity: 50%;
}

.vision > img {
    left: 0;
    right: unset;
    transform: rotate(180deg);
}

.mission-img,
.vision-img {
    width: 30vw;
    object-fit: cover;
    position: relative;
    z-index: 5;
}
.mission-flex {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    position: relative;
    z-index: 5;
    /* width: 800px; */
    /* border: 1px solid red; */
}
.mission-title {
    color: #2eaf7d;
    font-family: "Florencesans-Black";
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 137.5%;
    text-transform: uppercase;
}
.mission-hr {
    width: 15%;
    height: 8px;
    background: #014d4a;
}
.mission-subtitle {
    color: #000;
    font-family: "Milliard";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    width: 100%;
}

.mission-img-container,
.vision-img-container {
    position: relative;
}

.mission-img-container::before,
.vision-img-container::after {
    content: "";
    width: 100px;
    height: 100px;
    display: block;
    background: white;
    position: absolute;
    bottom: 0;
    border-radius: 500%;
    z-index: 2;
    transform: translate(25%, -25%);
    box-shadow: 0px 4px 10px rgba(0 0 0 / 0.2), 0px 6px 10px rgba(0 0 0/ 0.2);
    filter: blur(4px);
}

.vision-img-container::after {
    right: 0;
}

@media screen and (max-width: 900px) {
    .mission {
        flex-direction: column;
        gap: unset;
        padding: 10vh 5vw;
        overflow-x: hidden;
    }

    .mission-title {
        font-size: 2.5rem;
        margin-bottom: 4vh;
    }

    .mission-hr {
        margin-bottom: 2vh;
    }

    .mission-img {
        width: 90vw;
        margin-right: -30vw;
    }

    .mission-flex {
        margin-top: 0;
        gap: 0;
    }

    .mission-subtitle {
        font-size: 1rem;
    }

    .vision {
        height: unset;
        flex-direction: column;
        gap: unset;
        padding: 10vh 5vw;
    }

    .vision-img {
        width: 90vw;
        margin-right: 30vw;
        margin-bottom: 5vh;
    }

    .mission > img,
    .vision > img,
    .mission-img-container::before,
    .vision-img-container::after {
        display: none;
    }
}
