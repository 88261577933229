.resources-hero {
    padding: 2vh;
    background: url("../../../Assets/Images/resources-hero.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.resources-content {
    text-align: center;
    width: max-content;
}

.resources-hero-heading {
    font-size: 5.5rem;
    text-transform: uppercase;
    font-family: "MilliardBold";
    background: -webkit-linear-gradient(0deg, #c3c2c2 0%, #ffffff 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.resources-hero-subheading {
    font-size: 2rem;
    color: white;
}

.resources-hero-para {
    font-size: 1.5rem;
    text-transform: uppercase;
    color: white;
    padding-top: 5vh;
}

@media screen and (max-width: 1100px) {
    .resources-hero-heading {
        font-size: 4.5rem;
    }
    .resources-hero-subheading {
        font-size: 1.5rem;
    }

    .resources-hero-para {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 900px) {
    .resources-hero {
        justify-content: space-between;
    }
    .resources-content {
        max-width: 80vw;
    }

    .resources-hero-heading {
        font-size: 3rem;
    }

    .resources-hero-subheading {
        font-size: 1rem;
    }

    .resources-hero-para {
        font-size: 1rem;
    }
}
