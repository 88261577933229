.our-team {
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding: 84px;
    align-items: center;
    justify-content: center;
}
.our-team-text-flex {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 42px;
    width: 100%;
}
.ott-title {
    color: #2eaf7d;
    font-family: "Florencesans-Black";
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 91.667%;
    text-transform: uppercase;
}
.ott-hr {
    width: 10%;
    height: 8px;
    background: #014d4a;
}
.ott-subtitle {
    color: #000;
    font-family: "Milliard";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.team-flex-cont {
    width: 100vw;
    padding: 0 5vw;
    position: relative;
}

.team-flex {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    gap: 100px;
    align-items: center;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.team-flex::-webkit-scrollbar {
    display: none;
}
.about-arrow {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 2.5vw;
    cursor: pointer;
}
.about-arrow.right {
    left: unset;
    right: 2.5vw;
    transform: translate(50%, -50%) rotateZ(180deg);
}
.teamcard {
    /* width: 25vw; */
    height: 70vh;
    flex-shrink: 0;
    padding: 2px;
    /* background: linear-gradient(#626060, #fff); */
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border-radius: 80px 8px 80px 8px;
    transition: 0.2s;
}

.teamcard:hover {
    box-shadow: 3px 8px 15px 0 rgba(0 0 0 / 0.2);
}

.team-card-main {
    width: 100%;
    border-radius: 80px 8px 80px 8px;
    height: 100%;
    background-size: cover !important;
    background-position: center center !important;
    display: flex;
    align-items: flex-end;
    overflow: none;
    /* pointer-events: none; */
}
.team-card-flex {
    border-radius: 0px 0px 76px 4px;
    background: rgba(185, 176, 176, 0.4);
    backdrop-filter: blur(12px);
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
    padding: 10px 0px 0px 0px;
    width: 100%;
}
.team-card-title {
    color: var(--white, #fbfcf8);
    text-align: center;
    font-family: "Milliard";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.team-card-hr {
    border: none;
    border-top: 2px solid #fff;
    width: 40%;
}
.team-card-title-flex {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
}
.team-card-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    pointer-events: all;
}
.team-card-sub-title:first-of-type {
    font-size: 20px;
}
.team-card-sub-title {
    color: var(--white, #fbfcf8);
    text-align: center;
    font-family: "Milliard";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.teamcard-click {
    position: absolute;
    top: 0;
    border-radius: 80px 8px 80px 8px;
    width: 100%;
    height: 100%;
    padding: 10%;
    transform: translateY(calc(100% + 5px));
    transition: 0.4s ease-in-out;
    /* pointer-events: none; */
}
.team-card-para {
    color: white;
    font-size: 1rem;
    text-align: left;
    margin-top: 15px;
}

.toggle-translate {
    transform: translateY(0);
}

.toggle-visibility {
    visibility: hidden;
}

.team-card-para {
    height: 80%;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.team-card-para::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 900px) {
    .team-card-links {
        transform: translateY(0);
        opacity: 1;
    }
    .our-team {
        padding: 5vh 7vw;
    }
    .ott-title {
        font-size: 3rem;
    }
    .ott-hr {
        width: 40%;
    }
    .ott-subtitle {
        font-size: 1rem;
    }
    .team-flex {
        gap: 20px;
    }
    .team-card-flex {
        gap: 5px;
        padding: 5px 5px;
    }
    .team-card-title {
        font-size: 1.5rem;
    }
    .about-arrow {
        display: none;
    }
}
