.modules {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 23px; */
    padding: 40px 80px;
}
.modules-title {
    color: #2eaf7d;
    font-family: "Florencesans-Black";
    font-size: 6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}
.modules-subtitle {
    color: var(--dark-grey, #303030);
    font-family: Milliard;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
@media screen and (max-width: 700px) {
    .modules-title {
        font-size: 3rem;
    }
    .modules-subtitle {
        font-size: 1.2rem;
    }
    .modules {
        padding: 24px 7vw;
    }
}
